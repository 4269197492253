@import '@styles/basic';
.container {
  display: flex;
  justify-content: center;
  .wrapper {
    margin: 56px;
    @include mq($until: tablet) {
      margin-top: 24px;
      padding: 0 16px;
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
    }
    width: 100%;
    max-width: 1328px;

    .cardContainer {
      margin-top: 24px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 24px;

      grid-auto-rows: 1fr;

      @media screen and (max-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media screen and (max-width: 900px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;
      }
      @media screen and (max-width: 720px) {
        grid-template-columns: 1fr !important;
        grid-gap: 16px;
      }
    }
    .noFavoriteContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: calc(100vh - 184px);
      text-align: center;
      @include mq($until: tablet) {
        height: 100%;
        justify-content: flex-start;
      }
    }
    .blockTitle {
      margin-bottom: 24px;
      @include mq($until: tablet) {
        margin-bottom: 16px;
      }
    }
  }
}
