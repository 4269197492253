@import '@styles/basic';

.wrapperFilters {
  display: flex;
  margin-bottom: 40px;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 900px) {
    align-items: flex-start;
  }
  @media screen and (max-width: 820px) {
    display: block;
  }

  .filterContainer {
    overflow: hidden;
    min-height: 49px;
  }
  .selectWrapper {
    position: relative;
    z-index: 1;
    display: flex;
    max-width: 400px;
    @media screen and (max-width: 900px) {
      margin-top: 12px;
    }

    @media screen and (max-width: 820px) {
      margin: 24px 0;
      max-width: 320px;
    }

    &:hover::after {
      transition: opacity 300ms ease 0s;
      opacity: 0.5;
    }
    &::after {
      content: url(../../assets/img/chevron-down.svg);
      position: absolute;
      right: -12px;
      transform-origin: center;
      display: block;
      pointer-events: none;
      opacity: 1;
      top: 2px;

      @media screen and (max-width: 820px) {
        right: 76px;
      }
    }
    select {
      border: none;
      font-weight: 400;
      color: rgb(51, 51, 51);
      margin-left: 2px;
      margin-right: 2px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      box-sizing: border-box;
      outline: 0;
      padding: 2px 0;
      -webkit-appearance: none;
      height: 26px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 0;
      @media screen and (min-width: 820px) {
        margin-right: 12px;
      }

      @media screen and (max-width: 820px) {
        max-width: 300px;
      }

      option {
        font-weight: 400;
      }
    }

    &.lessWidth {
      max-width: 184px;
    }
  }
}
.cardContainer {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(4, 1fr);
  // grid-template-columns: repeat(auto-fit, minmax(314px, 1fr));
  grid-auto-rows: 1fr;

  @media screen and (max-width: 1400px) {
    grid-template-columns: repeat(3, 1fr);
    // grid-template-columns: repeat(auto-fit, minmax(314px, 1fr));
  }
  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
  }

  @media screen and (max-width: 710px) {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
}

.noFavoriteContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}

.showMoreContainer {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
