@import '@styles/basic';

.caption {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: var(--neutral400);
  text-transform: uppercase;
  margin-bottom: 8px;
}

.stepContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto 80px;
  max-width: 1160px;
  grid-gap: 40px;
  @include mq($until: tablet) {
    grid-template-columns: 1fr;
  }
}

.cardContainer {
  display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
  grid-auto-rows: 1fr;

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 580px) {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }

  &.hidden {
    margin-top: 24px;
    position: relative;

    @media screen and (max-width: 580px) {
      margin-top: 16px;
    }

    &::after {
      content: '';
      position: absolute;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 100%
      );
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
    }
    .bannerBlocker {
      z-index: 4;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 80%;
      top: 160px;
      border-radius: 24px;
      background-color: var(--blue);
      color: var(--white);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 640px) {
        padding: 16px;
      }

      h3 {
        margin-bottom: 40px;
        max-width: 608px;
        text-align: center;
      }
    }
  }
}
