@import '@styles/basic';
.container {
  display: flex;
  .stepWrapper {
    margin-right: 24px;
    flex-shrink: 0;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--orange-light);
    color: var(--orange);
    @include mq($until: desktop) {
      width: 42px;
      height: 42px;
      margin-right: 8px;
    }
  }

  .textWrapper {
  }
}
