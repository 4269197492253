@import '@styles/basic';
.container {
  height: 100%;
  max-width: 480px;
  padding-bottom: 24px;
  @include mq($from: desktop) {
    padding-top: 32px;
  }

  .inputContainer {
    position: relative;
    display: flex;
    z-index: 2;
    &:first-of-type {
      margin-top: 40px;
      margin-bottom: 8px;
    }

    &.isDisabled {
      label {
        color: var(--neutral400) !important;
      }
    }
    .inputField {
      -webkit-appearance: none;
      -webkit-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.04);
      border: 1px solid var(--neutral200);
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.04);
      border-radius: 8px;
      position: relative;
      padding: 16px;
      flex-direction: column;
      z-index: 3;
      background-color: transparent;
      font-size: 14px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      .radioContainer {
        width: 100%;
        display: flex;

        flex-direction: column;
        position: relative;

        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;

        &:first-of-type {
          margin-bottom: 8px;
        }

        .onFocusBorder {
          position: absolute;
          transition: opacity 0.15s ease-in-out;
          opacity: 0;
          top: 0;
          pointer-events: none;
          left: 0;
          box-shadow: 0 0 0 2px var(--blue);
          border-radius: 8px;
          width: 100%;
          height: 100%;
        }

        input {
          opacity: 0;
          cursor: pointer;
          z-index: 4;
          width: 100%;
          height: 100%;

          &:focus + .wrapperHelper .onFocusBorder {
            opacity: 1;
          }

          &:checked + .wrapperHelper .onFocusBorder {
            opacity: 1;
          }
        }

        span {
          position: absolute;
          top: 16px;
          left: 16px;
        }
      }

      .descriptionBox {
        margin-left: 32px;
        display: flex;
        justify-content: space-between;
        label {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: var(--neutral600);
        }
      }
      .checkround {
        position: absolute;
        top: 7;
        left: 12;
        height: 20px;
        width: 20px;
        background-color: var(--white);
        border-radius: 50%;
        border: 1px solid var(--neutral400);
      }

      .radioContainer input:checked ~ .checkround {
        background-color: var(--blue);
        border-color: var(--blue);
      }

      .checkround::after {
        content: '';
        position: absolute;
        display: none;
      }
      .radioContainer input:checked ~ .checkround::after {
        display: block;
      }

      .radioContainer .checkround::after {
        top: 5px;
        left: 5px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: var(--white);
      }
    }
  }
  .spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      margin-left: 8px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .hide {
    display: none;
  }

  .advice {
    margin-top: 16px;

    & + button {
      margin-top: 40px !important;
    }
  }
  .errorMessage {
    font-size: toRem(12);
    line-height: 1;
    margin-top: toRem(12);
    align-self: flex-start;
    color: var(--red);
  }
}
