@import '@styles/basic';
.container {
  text-align: center;

  max-width: 928px;
  margin: 0 auto 80px;

  &.smallText {
    margin: 0 auto 40px;
  }
  h2 {
    color: var(--neutral700);
    letter-spacing: 0;

    @extend %family-maquette-regular;

    @include mq($until: tablet) {
      font-weight: 400;
      font-size: 32px;
      line-height: 40px;
      max-width: 100%;
    }

    span {
      position: relative;
    }
  }

  h3 {
    text-align: left;
    span {
      position: relative;
    }
  }

  .vector {
    position: absolute;
    bottom: -4px;
    left: -8px;
    right: -8px;
    width: 100%;
  }

  p {
    color: var(--neutral700);
    @extend %family-maquette-regular;
    font-size: toRem(14);
    letter-spacing: 0;
    line-height: toRem(24);
    margin-bottom: toRem(40);
  }
}
