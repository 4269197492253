@font-face {
  font-family: 'ARS Maquette Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/ArsMaquettePro/arsmaquettepro-regular.woff2')
      format('woff2'),
    url('../assets/fonts/ArsMaquettePro/arsmaquettepro-regular.woff')
      format('woff'),
    url('../assets/fonts/ArsMaquettePro/arsmaquettepro-regular.ttf')
      format('truetype');
}

@font-face {
  font-family: 'ARS Maquette Pro';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/ArsMaquettePro/arsmaquettepro-medium.woff2')
      format('woff2'),
    url('../assets/fonts/ArsMaquettePro/arsmaquettepro-medium.woff')
      format('woff'),
    url('../assets/fonts/ArsMaquettePro/arsmaquettepro-medium.ttf')
      format('truetype');
}

@font-face {
  font-family: 'ARS Maquette Pro';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/ArsMaquettePro/arsmaquettepro-bold.woff2')
      format('woff2'),
    url('../assets/fonts/ArsMaquettePro/arsmaquettepro-bold.woff')
      format('woff'),
    url('../assets/fonts/ArsMaquettePro/arsmaquettepro-bold.ttf')
      format('truetype');
}

input,
select,
textarea {
  font-family: inherit;
}

h1 {
  font-weight: 700;
  font-size: 68px;
  line-height: 80px;
}

h2 {
  font-weight: 400;
  font-size: 48px;
  line-height: 64px;
}

h3 {
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;

  @include mq($until: desktop) {
    font-size: 24px;
    line-height: 32px;
  }
}

h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  @include mq($until: tablet) {
    font-size: 16px;
    line-height: 24px;
  }
}

.sub-header {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;

  &.bolder {
    font-weight: 500;
  }
}

.small {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--neutral400);
}

ul.small {
  margin-left: 24px;
  li {
    list-style: disc;
  }
}

.extra-small {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--neutral400);
}
