@import '@styles/basic';

.active {
  & > button {
    background-color: var(--blue);
  }
}
button.container {
  position: relative;
  display: flex;
  align-items: center;
  padding: toRem(12) toRem(16);
  border-radius: toRem(24);
  box-sizing: border-box;
  transition: background ease-in-out 0.2s;
  &.fullWidth {
    width: 100%;
    justify-content: center;
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > span {
    line-height: 24px;
    letter-spacing: 0.02em;
    font-size: toRem(16);
    @extend %family-maquette-regular;
    font-weight: 500;
  }

  &.text > div {
    display: none;
  }
  &.only-icon > span {
    display: none;
  }
  &.only-icon {
    border-radius: 50% !important;
    padding: toRem(12);
  }
  &.with-icon > div,
  &.with-icon > span {
    display: flex;
  }

  &.with-icon > span {
    display: flex;
  }

  &.with-icon > div {
    margin-right: toRem(8);
  }
  &.primary {
    background-color: var(--blue);
    color: var(--white);

    &:hover {
      background-color: var(--blue-hover);
    }

    &:disabled {
      background-color: var(--blue);
      opacity: 0.4;
    }

    &.outline {
      background-color: transparent;
      border: 1px solid var(--blue);
      color: var(--blue);
      &:hover {
        background-color: var(--blue-light);
        border: 1px solid var(--blue-hover);
        color: var(--blue);
      }

      &:disabled {
        background-color: transparent;
        border: 1px solid var(--blue-hover);
        color: var(--blue);
        opacity: 0.4;
      }
    }

    &.ghost {
      background-color: transparent;
      border: none;
      color: var(--blue);
      &:hover {
        background-color: var(--blue-light);
        border: none;
        color: var(--blue);
      }

      &:disabled {
        background-color: transparent;
        border: none;
        color: var(--blue);
        opacity: 0.4;
      }
    }

    &.menu {
      border-radius: 0;
      background-color: transparent;
      border: none;
      color: var(--blue);
      transition: none;
      padding: 0;
      height: 100%;
      &:first-of-type {
        margin-right: 16px !important;
      }
      &:nth-of-type(2) {
        margin-left: 16px !important;
      }
      &:not(.active):hover {
        color: var(--neutral600) !important;

        svg {
          stroke: var(--neutral600) !important;
        }
      }

      &:disabled {
        background-color: transparent;
        border: none;
        color: var(--blue);
        opacity: 0.4;
      }
      &.active {
        box-shadow: inset 0px -4px 0px var(--blue);
        @media screen and (max-width: 500px) {
          box-shadow: none;
        }
      }
    }
  }

  &.default.outline {
    background-color: transparent;
    color: var(--neutral600);
    border: 1px solid var(--neutral200);

    &:hover {
      background-color: var(--neutral100);
      border: 1px solid var(--neutral300);
      color: var(--neutral600);
    }

    &:disabled {
      background-color: transparent;
      opacity: 0.4;
      border: 1px solid var(--neutral200);
    }
  }

  &.default.ghost {
    background-color: transparent;
    color: var(--neutral600);
    border: none;

    &:hover {
      background-color: var(--neutral100);
      border: none;
      color: var(--neutral600);
    }

    &:disabled {
      background-color: transparent;
      opacity: 0.4;
      border: none;
    }
  }

  &.error {
    background-color: var(--red);
    color: var(--white);

    &:hover {
      background-color: var(--red-hover);
    }

    &:disabled {
      background-color: var(--red);
      opacity: 0.4;
    }

    &.outline {
      background-color: transparent;
      border: 1px solid var(--red);
      color: var(--red);
      &:hover {
        background-color: var(--red-light);
        border: 1px solid var(--red-hover);
        color: var(--red);
      }

      &:disabled {
        background-color: transparent;
        border: 1px solid var(--red);
        color: var(--red);
        opacity: 0.4;
      }
    }

    &.ghost {
      background-color: transparent;
      border: none;
      color: var(--red);
      &:hover {
        background-color: var(--red-light);
        border: none;
        color: var(--red);
      }

      &:disabled {
        background-color: transparent;
        border: none;
        color: var(--red);
        opacity: 0.4;
      }
    }
  }

  &.recording {
    background-color: var(--orange);
    color: var(--white);
    border: none;
    padding: toRem(20);
    &:hover {
      background-color: var(--orange-hover);
      border: none;
      color: var(--white);
    }
  }

  &.done {
    background-color: var(--green);
    color: var(--white);
    border: none;
    padding: toRem(20);
    &:hover {
      background-color: var(--green-hover);
      border: none;
      color: var(--white);
    }
    &:disabled {
      background-color: var(--green);
      opacity: 0.4;
    }
  }

  &.requested {
    background-color: var(--green);
    color: var(--white);
    border: none;
    padding: toRem(20);
    &:hover {
      cursor: default;
      background-color: var(--green);
    }
    &:disabled {
      background-color: var(--green);
      opacity: 0.4;
    }
  }
}
