@import '@styles/basic';

.container {
  margin-bottom: 40px;

  @include mq($until: tablet) {
    margin-bottom: 32px;
  }

  .noFavoriteContainer {
    margin-bottom: 40px;
    border: 1px solid var(--neutral200);
    border-radius: 8px;
    @include mq($until: tablet) {
      margin-bottom: 32px;
    }
    .step {
      display: flex;
      align-items: center;
      padding: 16px;
      &:not(:last-child) {
        border-bottom: 1px solid var(--neutral200);
      }
      .icon {
        margin-right: 16px;
        flex-shrink: 0;
        line-height: 32px;
        font-size: 18px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        @include mq($until: desktop) {
          width: 32px;
          height: 32px;
          margin-right: 8px;
        }

        &.icon1 {
          background-color: var(--orange-light);
        }
      }

      .content {
        color: var(--neutral600);

        p:nth-of-type(2) {
          color: var(--neutral400);
        }
      }
      .buttonContainer {
        margin-left: auto;
      }
    }
  }
}
