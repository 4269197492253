@import '@styles/basic';

.container {
  box-sizing: border-box;
  position: relative;
  padding: 40px;
  background: var(--white);

  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;

  @include mq($until: tablet) {
    box-shadow: none;
    padding: 20px;
  }

  .dataContainer {
    &.step1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .userDataWrapper {
      display: flex;
      margin-bottom: 24px;
      flex-direction: column;
      align-items: center;

      .closeModal {
        position: absolute;
        right: 28px;
        top: 28px;
        cursor: pointer;
      }
      .avatarContainer {
        & > div {
          cursor: default !important;
        }
      }
      .userData {
        margin-top: 8px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        .userInfo {
          display: flex;
          align-items: center;
          margin-top: 8px;

          color: var(--neutral600);

          svg {
            margin-right: 6px;
            flex-shrink: 0;
          }

          .separator {
            margin: 0 10px;
          }
        }
      }
    }

    .badgeContainer {
      display: flex;
      justify-content: center;
    }
    .description {
      margin: 24px 0;
      color: var(--neutral400);
      text-align: center;
    }

    .maxRequest {
      margin-top: -16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: var(--neutral400);
      margin-bottom: 24px;
    }
  }

  .error {
    color: var(--red);
    font-size: toRem(16);
    line-height: 1;
    margin-top: toRem(8);
    text-align: left;
    @media screen and (max-width: 416px) {
      margin-bottom: 18px;
      font-size: toRem(14);
    }
  }

  .success {
    color: var(--green);
    font-size: toRem(16);
    line-height: 1;
    margin-top: toRem(8);
    text-align: left;
    @media screen and (max-width: 416px) {
      margin-bottom: 18px;
      font-size: toRem(14);
    }
  }

  .footerCard {
    display: flex;
    flex-direction: column;

    button:first-of-type {
      margin-bottom: 16px;
    }
    button:nth-of-type(2) {
      max-height: 48px;
    }
    & > div {
      margin-bottom: 24px;

      width: 100%;

      audio {
        width: 100%;
      }
    }
  }
}
