@import '@styles/basic';

.container {
  box-sizing: border-box;
  padding: 24px;
  gap: 24px;
  background: var(--white);
  border: 1px solid var(--neutral200);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;
  &:hover {
    cursor: pointer;
    border: 1px solid var(--neutral300);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.04);
  }
  .dataContainer {
    .userDataWrapper {
      display: flex;
      margin-bottom: 16px;
      position: relative;
      .avatarContainer {
        margin-right: 16px;
        position: relative;
        .availableContainer {
          width: 24px;
          height: 24px;
          border-radius: 50%;

          border: 4px solid white;
          position: absolute;
          top: -4px;
          left: -4px;

          &.available {
            background-color: var(--green);
          }

          &.not_available {
            background-color: var(--neutral300);
          }
        }
      }
      .userData {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .userInfo {
          display: flex;
          align-items: center;

          span {
            color: var(--neutral600);
          }
          svg {
            margin-right: 6px;
            flex-shrink: 0;
          }

          .voiceType {
            flex-shrink: 0;
          }
          .separator {
            margin: 0 8px;
          }
        }
      }

      .like {
        position: absolute;
        right: 0;
        svg {
          cursor: pointer;
          margin-top: 6px;
          transition: all 0.2s ease-in-out;
          &:hover {
            stroke: var(--neutral600) !important;
          }
        }
      }
    }
  }

  .footerCard {
    display: flex;
    flex-direction: column;

    & > div {
      margin-bottom: 24px;

      width: 100%;

      audio {
        width: 100%;
      }
    }
  }
}
