@import '@styles/basic';

.container {
  margin-top: 240px;
  .wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .languages {
      display: flex;
      justify-content: center;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 8px;
      ul {
        display: flex;
        li {
          color: var(--neutral400);
          span {
            &:hover {
              color: var(--neutral200);
            }
          }
          &:not(:last-child)::after {
            content: '\A0\A0\B7\A0\A0';
            display: inline-block;
          }
        }
      }
    }
    .company {
      display: flex;
      justify-content: center;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 8px;
      ul {
        display: flex;
        li {
          color: var(--neutral400);
          a {
            color: var(--neutral400);
          }
          &:last-child {
            span {
              text-decoration: underline;
              cursor: pointer;
              &:hover {
                color: var(--neutral200);
              }
            }
          }

          &:not(:last-child)::after {
            content: '\A0\A0\B7\A0\A0';
            display: inline-block;
          }
        }
      }
    }
  }
}
