@import '@styles/basic';

.header {
  position: relative;
  position: sticky;
  top: 0;
  display: flex;
  background-color: var(--white);
  box-shadow: inset 0px -1px 0px var(--neutral200);
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  // padding: 12px 16px;
  padding: 0 16px;
  min-height: 72px;
  @media print {
    display: none !important;
  }

  @include mq($from: tablet) {
    padding: 0 24px;
  }

  .logo {
    display: block;

    height: 40px;
    flex-shrink: 0;
    align-self: center;
  }

  .navigation {
    height: inherit !important;
    display: flex;
    align-items: center;
    margin-left: -80px;
    button {
      &:first-of-type {
        margin-right: 8px;
      }
    }
  }
}

// @import '@styles/basic';

// .header {
//   position: relative;
//   position: sticky;
//   top: 0;

//   background-color: var(--white);

//   z-index: 2;

//   @media print {
//     display: none !important;
//   }

//   & > div {
//     padding: 0 24px;
//     box-shadow: inset 0px -1px 0px var(--neutral200);
//     justify-content: space-between;
//     align-items: center;
//     margin: 12px 0px;
//     display: flex;
//     padding-bottom: 12px;
//     @include mq($from: tablet) {
//       margin: 16px 0px;
//     }
//   }

//   .logo {
//     display: block;
//     margin-right: 16px;
//     height: 40px;
//     flex-shrink: 0;
//   }

//   .navigation {
//     display: flex;
//     align-items: center;
//     button {
//       margin: 0 !important;
//     }
//   }
// }
