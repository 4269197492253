@import '@styles/basic';

.container {
  position: relative;
  display: flex;
  align-items: center;
  padding: toRem(4) toRem(12);
  border-radius: toRem(16);
  box-sizing: border-box;
  transition: background ease-in-out 0.2s;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */

  &.available {
    background-color: var(--green);
    color: var(--white);
  }

  &.not_available {
    background-color: var(--neutral300);
    color: var(--white);
  }
}
