@import '@styles/basic';
.container {
  display: flex;
  justify-content: center;
  .wrapper {
    margin-top: 80px;
    @include mq($until: tablet) {
      margin-top: 24px;
    }
    width: 100%;
    max-width: 640px;
    padding: 0 16px;
    .user {
      display: flex;
      justify-content: space-between;
      margin-bottom: 48px;
      align-items: center;
      @include mq($until: tablet) {
        margin-bottom: 24px;
      }
      @media screen and (max-width: 340px) {
        flex-direction: column;
        align-items: flex-start;
      }

      button {
        @media screen and (max-width: 340px) {
          margin-top: 8px;
        }
      }
    }
    .blockTitle {
      margin-bottom: 24px;
      @include mq($until: tablet) {
        margin-bottom: 16px;
      }
    }
  }
}
