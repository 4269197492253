@import '@styles/basic';

.container {
  box-sizing: border-box;
  padding: 24px;
  gap: 24px;
  background: var(--white);
  border: 1px solid var(--neutral200);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
  transition: all 0.2s ease-in-out;
  &:hover {
    border: 1px solid var(--neutral300);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.04);
  }
  .dataContainer {
    .userDataWrapper {
      display: flex;
      margin-bottom: 16px;
      .avatarContainer {
        margin-right: 16px;
      }
      .userData {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .userInfo {
          display: flex;
          align-items: center;

          span {
            color: var(--neutral600);
          }
          svg {
            margin-right: 6px;
            flex-shrink: 0;
          }

          .separator {
            margin: 0 10px;
          }
        }

        & + div > svg {
          transition: color 0.2s ease-in-out;
          &:hover {
            cursor: pointer;
            color: var(--neutral600);
          }
        }
      }
    }
  }

  .footerCard {
    display: flex;
    flex-direction: column;

    & > div {
      width: 100%;

      audio {
        width: 100%;
      }
    }
  }
}
