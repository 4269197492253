@import '@styles/basic';
.avatarWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 4px;
  border-color: #ffffff;
  background-color: var(--neutral400);
  border-radius: 50%;
  flex-shrink: 0;
  align-self: center;
  span {
    color: var(--white);
    font-weight: 400;
    font-family: 'ARS Maquette Pro';
    text-transform: uppercase;
  }

  .avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 50%;
    height: 100%;
    object-fit: cover;
  }

  &.big {
    width: 88px;
    height: 88px;

    span {
      font-size: 43px;
      line-height: 40px;
      text-transform: uppercase;
    }
  }

  &.default {
    width: 48px;
    height: 48px;

    span {
      font-size: 20px;
      line-height: 24px;
      text-transform: uppercase;
    }
  }

  &.medium {
    width: 64px;
    height: 64px;

    span {
      font-size: 20px;
      line-height: 24px;
      text-transform: uppercase;
    }
  }

  &.small {
    width: 32px;
    height: 32px;

    span {
      font-size: 14px;
      line-height: 24px;
      text-transform: uppercase;
    }
  }

  &.x-small {
    width: 20px;
    height: 20px;

    span {
      font-size: 10px;
      line-height: 12px;
      text-transform: uppercase;
    }
  }
}
