@import '@styles/basic';
.control {
  display: block;
  position: relative;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.control input {
  @extend %family-maquette-regular;
  display: block;
  color: var(--neutral600);
  margin-top: 4px;
  padding: toRem(12);
  height: 100%;
  line-height: toRem(24);
  font-size: toRem(16);
  outline: 0;
  max-width: 104px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  border: 1px solid var(--neutral300);
  position: relative;

  height: 48px;
  &::placeholder {
    color: var(--neutral300);
  }

  &:hover {
    border: 1px solid var(--neutral400);
  }
}

.control span {
  opacity: 0.4;
}
.controlIndicator {
  position: absolute;
  top: 4px;
  left: 0;
  height: 16px;
  width: 16px;
  background: white;
  border: 1px solid var(--neutral300);
  border-radius: 4px;
}
.control:hover input ~ .controlIndicator,
.control input:focus ~ .controlIndicator {
  background: white;
}

.control input:checked ~ .controlIndicator {
  background-color: var(--blue);
  border-color: var(--blue) !important;
}
.control:hover input:not([disabled]):checked ~ .controlIndicator,
.control input:checked:focus ~ .controlIndicator {
  background: var(--blue);
  border-color: var(--blue) !important;
}
.control input:disabled ~ .controlIndicator {
  background: #d3d3d3;
  opacity: 4;
  pointer-events: none;
}
.controlIndicator:after {
  box-sizing: unset;
  content: '';
  position: absolute;
  display: none;
}
.control input:checked ~ .controlIndicator:after {
  display: block;
  right: -1px;
  top: -1px;
  border: none;
  padding: 8.2px;
  background-image: url(../../assets/img/checkbox-tick.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  content: '';
}
.controlCheckbox .controlIndicator:after {
  // left: 8px;
  // top: 2px;
  // width: 4px;
  // height: 12px;
  // border: solid white;
  // border-width: 0 3px 3px 0;
  // transform: rotate(45deg);
}
.controlCheckbox input:disabled ~ .controlIndicator:after {
  border-color: #c0c0c0;
}
