@import './variables';
@import 'sass-mq';
@import 'scss-reset/_reset.scss';
@import './lib/css-vars';
@import './functions';
@import './fontface';
@import './placeholders';

html {
  width: 100%;
  height: 100%;
  // height: -webkit-fill-available;

  @include css-vars(
    (
      --blue: $blue,
      --blue2: $blue2,
      --light-blue: $light-blue,
      --dark-blue: $dark-blue,
      --blue-hover: $blue-hover,
      --blue-light: $blue-light,
      --blue-light2: $blue-light2,
      --white: $white,
      --yellow: $yellow,
      --yellow-light: $yellow-light,
      --green: $green,
      --green-hover: $green-hover,
      --green-light: $green-light,
      --orange: $orange,
      --orange-hover: $orange-hover,
      --orange-light: $orange-light,
      --purple: $purple,
      --purple-light: $purple-light,
      --purple-light2: $purple-light2,
      --red: $red,
      --red-hover: $red-hover,
      --red-light: $red-light,
      --neutral100: $neutral100,
      --neutral200: $neutral200,
      --neutral300: $neutral300,
      --neutral400: $neutral400,
      --neutral500: $neutral500,
      --neutral600: $neutral600,
    ),
    'html'
  );
}

body,
html {
  @extend %family-maquette-regular;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  box-sizing: border-box;
  overscroll-behavior-y: none !important;
  text-rendering: optimizeLegibility;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  min-height: unset !important;
  // min-height: auto !important;
  // min-height: -webkit-fill-available;
  // overscroll-behavior-y: contain !important;
}

input[type='date'].isIos {
  -webkit-appearance: none !important;
  z-index: 9999 !important;
  background-color: #fff !important;
}

input[type='date'].isIos::after {
  color: var(--neutral600);
  content: attr(placeholder);
}
input[type='date'].notEmpty.isIos::after {
  content: none !important;
}

input[type='date'].isAndroid {
  -webkit-appearance: none !important;
  z-index: 9999 !important;
  background-color: #fff !important;
}

input[type='date'].isAndroid::after {
  color: var(--neutral600);
  content: attr(placeholder);
  top: 10px !important;
  position: absolute !important;
}
input[type='date'].notEmpty.isAndroid::after {
  content: none !important;
}

form {
  button {
    margin-top: 32px !important;
    @include mq($until: tablet) {
      margin: 24px 0 !important;
    }
  }
}

a {
  text-decoration: none;
  color: var(--blue);

  &:hover {
    color: var(--blue-hover);
    text-decoration: underline;
  }
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
@page {
  size: auto;
  margin: 0;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  background: rgba(0, 0, 0, 0.5) !important;
  min-height: 1px;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReactModalPortal {
  position: fixed;
  z-index: 999999999;
  min-height: 1px;
}

.ReactModal__Content {
  outline: 0 !important;
  margin: auto;
  border-radius: 8px !important;
  border: 1px solid var(--neutral300) !important;
  padding: 0 !important;
  position: unset !important;
  width: 520px;
  bottom: auto !important;
  border: none;
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.16);

  @include mq($until: tablet) {
    max-width: 100%;
    max-height: 100%;
    inset: 0 !important;
    width: 100%;
    position: fixed !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: 0 !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    margin-right: 0 !important;
  }
}

.react-contexify {
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.08) !important;
  border-radius: 12px !important;
  min-width: 280px !important;
  border: 1px solid var(--neutral200) !important;

  padding: 0 !important;
}

.react-contexify .react-contexify__item__content {
  padding: 2px 0 !important;
  padding-bottom: 0 !important;
  display: block !important;
}

.react-contexify
  .react-contexify__item:first-child
  .react-contexify__item__content {
  padding-top: 0 !important;
}

.react-contexify
  .react-contexify__item:last-child
  .react-contexify__item__content {
  padding-bottom: 0 !important;
}

.react-contexify .react-contexify__item__content {
  background: white !important;
  color: var(--black) !important;
}

// ------------------------------------

/* custom style input range  */

.input-range {
  padding: 18px 0;
}
.input-range__track--background {
  height: 4px;
  border-radius: 0;
  background-color: var(--dark-grey);
}
.input-range__track--active {
  background-color: var(--red);
  height: 4px;
  border-radius: 0;
}

.input-range__slider {
  width: 40px;
  height: 40px;
  background-color: var(--white);
  border: 1px solid var(--dark-grey);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
  margin-left: calc(40px / -2);
  margin-top: calc(40px / -2 + 4px / -2);
}

.input-range__slider {
  transform: scale(1) !important;
}

.input-range--disabled {
  opacity: 0.5 !important;
  cursor: initial !important;
}

.input-range--disabled .input-range__slider {
  cursor: initial !important;
}

.input-range__slider:after {
  content: url('./../assets/img/checkbox-tick.svg');
  position: absolute;
  left: 50%;
  top: 50%;
  color: #979797;
  transform-origin: center;
  display: block;
  transform: translate(-50%, -50%) rotate(90deg);
}

.input-range__label-container {
  display: none;
}

// -------------------------------

.PhoneInput {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  border: 1px solid var(--neutral300);
  position: relative;
  padding: 1px;
  padding-left: 0;
  height: 48px;
  margin-top: 8px;
  transition: all 0.15s ease-in-out;
  &--focus {
    box-shadow: 0 0 0 2px var(--blue);
    border: 0;
  }

  &.error {
    box-shadow: 0 0 0 2px var(--red);
    border: 0;
  }

  &:hover {
    border: 1px solid var(--neutral300);
  }
  .PhoneInputCountry {
    padding: 0.75rem 1rem;
  }
  input {
    @extend %family-maquette-regular;
    display: block;
    color: var(--neutral600);
    width: 100%;
    padding: toRem(12) toRem(16);
    height: 100%;
    line-height: toRem(24);
    font-size: toRem(16);
    outline: 0;
    border: 0;

    &::placeholder {
      color: var(--neutral300);
    }
    &:focus + .wrapperHelper .onFocusBorder {
      opacity: 1;
    }
    &:disabled {
      background-color: var(--neutral100) !important;
    }
  }

  .PhoneInputInput {
    padding-left: 0;
  }
}

// TOOLTIP

.rc-tooltip-placement-top .rc-tooltip-arrow-inner,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow-inner,
.rc-tooltip-placement-topRight .rc-tooltip-arrow-inner {
  background: var(--neutral500) !important;
}

.rc-tooltip-arrow {
  border-top-color: var(--neutral500) !important;
  margin-left: 0 !important;
}

.rc-tooltip.rc-tooltip-placement-top {
  max-width: 13rem !important;

  opacity: none !important;
  font-size: 12px !important;
  background-color: transparent !important;
  box-shadow: 0px 4px 16px rgba(13, 17, 23, 0.24) !important;
  background: var(--neutral500) !important;
  border-radius: 2px;

  .rc-tooltip-content {
    .rc-tooltip-inner {
      background: var(--neutral500) !important;
      border-radius: 2px !important;
      border: transparent !important;
      padding: 4px !important;
      min-height: auto;
      color: #ffffff !important;
      p,
      li {
        font-size: 12px !important;
        font-weight: 400 !important;
        line-height: 16px !important;
        color: #ffffff !important;
      }
    }
  }
}
